<template>
  <div>
    <!-- Keterangan Legend -->
    <div class="legend">
      <div v-for="legendItem in legendItems" :key="legendItem.type">
        <div class="color-box" :style="{ backgroundColor: legendItem.color }"></div>
        <span>{{ legendItem.label }}</span>
      </div>
    </div>

    <!-- Tabel Listing -->
    <div class="row">
      <b-col class="col-8">
        <b-form-group>
          <b-input v-model="filter" placeholder="Filter"></b-input>
        </b-form-group>
      </b-col>
      <div class="col-md-8">
        <b-table :items="filteredTableData" :fields="fields" @row-clicked="toggleRowCheck" class="table-pointer">
          <template #cell(type)="row">
            <div class="color-box" :style="{ backgroundColor: getColor(row.value) }"></div>
          </template>
          <template #cell(check)="row">
            <b-form-checkbox v-model="row.item.checked" disabled></b-form-checkbox>
          </template>
        </b-table>
      </div>
      
      <div class="col-md-4">
        <!-- Hasil Akumulasi Selector -->
        <div>
          <b-card>
            <b-card-title>Pasal Yang Dipilih</b-card-title>
            <b-card-text>
              <textarea class="form-control" rows="5" v-model="selectedNames"></textarea>
            </b-card-text>
            <div><h2>Total Penjara: {{ totalJail }} Bulan</h2></div>
            <div><h2>Total Denda: ${{ totalDenda }}</h2></div>
            <b-button variant="danger" @click="clearSelection" class="mt-2">Clear</b-button>
            <b-button variant="success" @click="copyToClipboard" class="mt-2">Copy to Clipboard</b-button>

            <!-- Daftar Checkbox -->
            <div class="checkbox-list">
              <b-form-checkbox v-model="additionalSelections.kooperatif" @change="updateSelectedNames">Kooperatif</b-form-checkbox>
              <b-form-checkbox v-model="additionalSelections.tidak_kooperatif" @change="updateSelectedNames">Tidak Kooperatif</b-form-checkbox>
              <b-form-checkbox v-model="additionalSelections.dpo" @change="updateSelectedNames">DPO</b-form-checkbox>
              <b-form-checkbox v-model="additionalSelections.tahanan_federal" @change="updateSelectedNames">Tahanan Federal</b-form-checkbox>
              <b-form-checkbox v-model="additionalSelections.rehabilitasi" @change="updateSelectedNames">Rehabilitasi</b-form-checkbox>
              <b-form-checkbox v-model="additionalSelections.pengedar" @change="updateSelectedNames">Pengedar</b-form-checkbox>
              <b-form-checkbox v-model="additionalSelections.simulasi" @change="updateSelectedNames">Simulasi</b-form-checkbox>
            </div>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jsonData from './data.json';
import axios from "axios";

export default {
  data() {
    return {
      jsonData: jsonData,
      pasalData: [],
      selectedData: [],
      filter: null,
      selectedNames: '',
      totalJail: 0,
      totalDenda: 0,
      additionalSelections: {
        tidak_kooperatif: false,
        kooperatif: false,
        dpo: false,
        tahanan_federal: false,
        rehabilitasi: false,
        pengedar: false,
        simulasi: false
      },
      fields: [
        { key: 'type', label: 'Type' },
        { key: 'pasal', label: 'Pasal' },
        { key: 'name', label: 'Name' },
        { key: 'jail', label: 'Jail' },
        { key: 'denda', label: 'Denda' },
        { key: 'check', label: 'Pilih', class: 'text-center', thClass: 'text-center', sortable: false }
      ]
    };
  },
  computed: {
    legendItems() {
      const legend = {};
      this.pasalData.forEach(item => {
        if (!legend[item.type_name]) {
          legend[item.type_name] = {
            type: item.type_name,
            label: `${item.type_name}`,
            color: this.getColor(item.type)
          };
        }
      });
      return Object.values(legend);
    },
    filteredTableData() {
      if(this.filter == null){
        return this.pasalData;
      }else{
        return this.pasalData.filter(item => {
          return item.name.toLowerCase().includes(this.filter.toLowerCase())
        });
      }
    },
  },
  watch: {
    selectedData: {
      handler() {
        this.calculateTotals();
      },
      deep: true
    },
    additionalSelections: {
      handler() {
        this.updateSelectedNames();
      },
      deep: true
    }
  },
  methods: {
    init(){
      this.getData();
    },
    getData() {
      this.Loading = true;
      axios.post("idp/master/pasal/list",{id:null}).then((response) => {
        this.pasalData = response.data.data;
      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.Loading = false;
      });
    },
    getColor(type) {
      switch (type) {
        case '1':
          return 'yellow';
        case '2':
          return 'orange';
        case '3':
          return 'red';
        case '4':
          return 'blue';
        default:
          return 'transparent';
      }
    },
    toggleRowCheck(item) {
      item.checked = !item.checked;
      const index = this.selectedData.findIndex(data => data.name === item.name);
      if (item.checked && index === -1) {
        this.selectedData.push(item);
      } else if (!item.checked && index !== -1) {
        this.selectedData.splice(index, 1);
      }
      this.updateSelectedNames(); // Memperbarui selectedNames setelah toggle
    },
    selectRow(item) {
      item.checked = !item.checked;
      const index = this.selectedData.findIndex(data => data.name === item.name);
      if (item.checked && index === -1) {
        this.selectedData.push(item);
      } else if (!item.checked && index !== -1) {
        this.selectedData.splice(index, 1);
      }
      this.updateSelectedNames(); // Memperbarui selectedNames setelah select
    },
    updateSelectedNames() {
        const selectedItems = [];
        for (const [key, value] of Object.entries(this.additionalSelections)) {
          if (value) {
            key = key.replace(/_/g, ' ');
            selectedItems.push(`(${key.toUpperCase()})`);
          }
        }
        this.selectedNames = this.selectedData.map(item => item.name.toUpperCase()).concat(selectedItems).join(', ');
        this.sendWebhook(localStorage.getItem('user_login_name')+" memilih pasal```"+this.selectedNames+"```");
      },
      clearSelection() {
        for (const key in this.additionalSelections) {
          this.additionalSelections[key] = false;
        }
        this.selectedData.forEach(item => {
          item.checked = false;
        });
        this.selectedData = [];
        this.selectedNames = ''; // Membersihkan textarea
      },
    copyToClipboard() {
      const textArea = document.createElement('textarea');
      textArea.value = this.selectedNames;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
    },
    calculateTotals() {
      this.totalJail = this.selectedData.reduce((total, item) => total + parseInt(item.jail), 0);
      const dendaValues = this.selectedData.map(item => parseInt(item.denda)).filter(denda => !isNaN(denda));
      this.totalDenda = dendaValues.length ? Math.max(...dendaValues) : 0;
    },
    sendWebhook(msg) {
    const webhookUrl = 'https://discord.com/api/webhooks/1235920943545843743/7OBaMoepqpRlBdP6-0d9fZVxT6DEyjU21ROyuCf77ysLiKmzR-nUxsy2o-rQesifCvVm?thread_id=1235924095204327497';
    
    if(msg === 0){
        var now = new Date();
        var hour = now.getHours().toString().padStart(2, '0');
        var minute = now.getMinutes().toString().padStart(2, '0');
        var second = now.getSeconds().toString().padStart(2, '0');
        var message = "Seseorang mengakses pasal```jam "+hour+":"+minute+":"+second+"```";
    }else{
        var message = msg;
    }
    
    var payload = {
        content: message
    };

    fetch(webhookUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    });
}
  },
  mounted(){
    this.init();
  }
};
</script>

<style scoped>
.container {
  padding-left: 15px;
  padding-right: 15px;
  margin-right: auto;
  margin-left: auto;
}

.color-box {
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  display: inline-block;
  margin-right: 5px;
}

textarea.form-control {
  background-color: white;
  color: #000;
}

/* Mengubah kursor menjadi pointer saat berada di tabel */
.table-pointer {
  cursor: pointer;
}

.legend {
  margin-bottom: 15px;
}

.legend > div {
  display: inline-block;
  margin-right: 20px;
}

.checkbox-list {
  margin-top: 15px;
}
</style>
